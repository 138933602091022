import React from "react";
import { Link } from "react-router-dom";
import { motion, cubicBezier } from "framer-motion";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from 'swiper/react';


//Style
import 'swiper/css';
//components
import Header from "../components/header";
import Footer from "../components/footer";
//Images
import HeroImg from "../assets/menu-img-01.jpg";
import VideoThumb from "../assets/vide-bg-img.jpg";
import { ReactComponent as VideoPlayIcon } from "../assets/play-icon.svg";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";

//Slider images
import { galleryPhotos } from "../assets/photos";

//Transiton ease
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const About = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="animate"
        exit={{ opacity: 0 }}
        transition={transition}
        className="content has-bottom-border"
      >
        <div className="hero-section">
          <motion.span
            initial={{
              left: 0,
              opacity: 0,
            }}
            animate={{
              left: 60,
              opacity: 1,
              transition: { delay: 0.9, ...transition },
            }}
            className="hero-back"
          >
            <Link to="/">
              <ArrowLeft />
            </Link>
          </motion.span>
          <motion.h2
            initial={{
              y: 30,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.8, ...transition },
            }}
            className="hero-title"
          >
            О нама
          </motion.h2>
          <motion.div
            initial={{
              width: "calc(100% - 120px)",
              height: "calc(100vh - 360px)",
            }}
            animate={{
              width: "100%",
              height: window.innerWidth > 1440 ? 500 : 350,
              transition: { delay: 0.2, ...transition },
            }}
            className="hero-img-holder"
          >
            <motion.img
              initial={{
                opacity: 0.4,
                scale: 1.05,
              }}
              animate={{
                transition: { delay: 0.2, ...transition },
              }}
              src={HeroImg}
              alt="Идзуо - о нама"
            />
          </motion.div>
        </div>

        <div className="content-in">
          <div className="content-block">
            <motion.h4
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
            >
              Ко смо ми и шта радимо?
            </motion.h4>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
              className="justify"
            >
              Милош Мишовић завршио је Средњу угоститељску школу у Врњачкој
              Бањи, која важи за најпрестижнију у тој области у Србији. Након
              школовања, радио је у Крагујевцу у локалним угоститељским радњама.
              Након тога одлази у Београд са циљем да упозна различите кухиње и
              културе, интернационалног и разноврсног садржаја. Из ресторана
              “Жабар” у Београду, као истакнути појединац, добија понуду за рад
              у Москви. После три године живота и рада у Београду, Милош одлази
              у руску престоницу и наставља гастрономску авантуру у српском
              ресторану. Тамо учи руски језик и наставља да ради у рибљем
              ресторану, где се упознаје са начином спремања меса у “Су вид”
              уређају.
            </motion.p>
          </div>
          <Swiper
            spaceBetween={50}
            loop={true}
            speed={300}
            navigation
            slideToClickedSlide={true}
            breakpoints={{
              1440: {
                slidesPerView: 4,
              },
              980: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
                centeredSlides: true,
              },
              240: {
                slidesPerView: 1,
              },
            }}
          >
            {galleryPhotos.map((photo, index) => (
              <SwiperSlide key={index}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={transition}
                  className="slide-img"
                  style={{ backgroundImage: `url(${photo.image})` }}
                ></motion.div>
              </SwiperSlide>
            ))}
          </Swiper>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-block"
          >
            <h4>Како спремамо нашу храну</h4>
            <p className="justify">
              Код нас можете пробати јединствену храну у граду. Тајна је у
              начину припреме меса и његовом чувању. Уређај ,,sous - vide‘‘
              припрема месо на посебан начин. Месо се ставља у вакум врећице,
              затим у врелу воду где се равномерно термички обрађује, чувајући
              све своје сокове. Температура и време спремања се крећу од 45 до
              75 степени, у трајању од 6 до 14 часова у зависности од врсте
              меса. Тако припремљено месо се вади из врећица, чисти од костију,
              ситно сецка и сервира у тортиљи или у салати.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="player-wrapper row v-center h-center"
          >
            <ReactPlayer
              playing
              controls={true}
              light={VideoThumb}
              width="1200px"
              height="550px"
              volume={0.1}
              playIcon={
                <button className="video-play">
                  <VideoPlayIcon />
                </button>
              }
              url="http://idzuo.com/swiperImages/about-video.mp4"
            />
          </motion.div>

          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="footer-links"
          >
            <li>
              <Link className="fancy-link" to="/menu">
                <span data-hover="Погледајте наш јеловник и поручите">
                  Погледајте наш јеловник и поручите
                </span>
              </Link>
            </li>
            {/* <li>
              <Link className="fancy-link" to="/reservations">
                <span data-hover="Резервишите сто">Резервишите сто</span>
              </Link>
            </li> */}
            {/* <li>
              <Link className="fancy-link" to="/order">
                <span data-hover="Поручите за доставу">
                  Поручите за доставу
                </span>
              </Link>
            </li> */}
          </motion.ul>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default About;
