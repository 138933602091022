import React from "react";
import { motion, cubicBezier } from "framer-motion";

//Data
import { smashOriginalData } from "../data/smashOriginalData";

//Default transitioncategories
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const vegan = (props) => {
  const { setActiveObject, workingHours, getClass } = props;
  return (
    <>
      <motion.h4
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2.4, ...transition }}
        className="menu-title"
      >
        <span>Original Idzuo Smash</span>
      </motion.h4>
      <ul className="list-menu smash">
        {smashOriginalData.map((product) => (
          <motion.li
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2.4, ...transition }}
            key={product.id}
            onClick={() => {
              setActiveObject(product);
              workingHours();
            }}
            className={getClass(product.id)}
          >
            <img  className="list-img" src={product.image} alt="Ресторан идзуо" />
            <span className="list-menu-info">
              <span className="list-menu-top">
                <span className="list-title">{product.name}</span>
                <span className="list-desc">{product.desc}</span>
              </span>
              <span className="list-price">{product.price} рсд</span>
            </span>
          </motion.li>
        ))}
      </ul>
    </>
  );
};

export default vegan;
