export const breakfastData = [
    {
      id: 1,
      name: "Омлет у тортиљи",
      subtitle: "Тортиља",
      desc: "2 јаја, фета сир, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "220",
      image: "https://idzuo.com/menuImages/breakfast/omlet_u_tortilji.jpg",
      cart: "Додајте у корпу",
    },
    {
      id: 2,
      name: "Омлет на тањиру",
      subtitle: "Taњир",
      desc: "3 јаја, фета сир, микс салата, ½ тортиље",
      grams: "",
      price: "240",
      image: "https://idzuo.com/menuImages/breakfast/omlet.avif",
      cart: "Додајте у корпу",
    },

    {
      id: 3,
      name: "Омлет са панчетом у тортиљи",
      subtitle: "Тортиља",
      desc: "2 јаја, панчета, фета сир, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "290",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_pancetom_u_tortilji.jpg",
      cart: "Додајте у корпу",
    },
    {
      id: 4,
      name: "Омлет са панчетом на тањиру",
      subtitle: "Taњир",
      desc: "3 јаја, панчета, фета сир, микс салата, ½ тортиље",
      grams: "",
      price: "310",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_pancetom.avif",
      cart: "Додајте у корпу",
    },

    {
      id: 5,
      name: "Омлет са печеницом у тортиљи",
      subtitle: "Тортиља",
      desc: "2 јаја, печеница, фета сир, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "250",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_pecenicom_u_tortilji.jpg",
      cart: "Додајте у корпу",
    },
    {
      id: 6,
      name: "Омлет са печеницом на тањиру",
      subtitle: "Taњир",
      desc: "3 јаја, печеница, фета сир, микс салата, ½ тортиље",
      grams: "",
      price: "270",
      image: "https://idzuo.com/menuImages/breakfast/omlet_pecenica.avif",
      cart: "Додајте у корпу",
    },

    {
      id: 7,
      name: "Омлет са печуркама у тортиљи",
      subtitle: "Тортиља",
      desc: "2 јаја, печурке, фета сир, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "240",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_pecurkama_u_tortilji.jpg",
      cart: "Додајте у корпу",
    },
    {
      id: 8,
      name: "Омлет са печуркама на тањиру",
      subtitle: "Taњир",
      desc: "3 јаја, печурке, фета сир, микс салата, ½ тортиље",
      grams: "",
      price: "260",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_pecurkama.avif",
      cart: "Додајте у корпу",
    },

    {
      id: 9,
      name: "Омлет са димљеном буткицом и печуркама у тортиљи",
      subtitle: "Тортиља",
      desc: "2 јаја, буткица, печурке, фета сир, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "290",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_butkicom_i_pecurkama_u_tortilji.jpg",
      cart: "Додајте у корпу",
    },
    {
      id: 10,
      name: "Омлет са димљеном буткицом и печуркама на тањиру",
      subtitle: "Taњир",
      desc: "3 јаја, буткица, печурке, фета сир, микс салата, ½ тортиље",
      grams: "",
      price: "310",
      image: "https://idzuo.com/menuImages/breakfast/omlet_butkica_pecurke.avif",
      cart: "Додајте у корпу",
    },

    {
      id: 11,
      name: "Омлет са кобасицом у тортиљи",
      subtitle: "Тортиља",
      desc: "2 јаја, кобасица са сиром, фета сир, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "500",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_kobasicom_tortilji.jpg",
      cart: "Додајте у корпу",
    },
    {
      id: 12,
      name: "Омлет са кобасицом на тањиру",
      subtitle: "Тортиља",
      desc: "3 јаја, аустријска кобасица са качкављем, фета сир, микс салата, ½ тортиље",
      grams: "",
      price: "510",
      image: "https://idzuo.com/menuImages/breakfast/omlet_sa_kobasicom_sa_kackavaljem_na_tanjiru.avif",
      cart: "Додајте у корпу",
    },
];
  