import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { motion, cubicBezier } from "framer-motion";
//components
import Header from "../components/header";
import Footer from "../components/footer";
//FUNCTIONS
import { handleImageReturn, handleImage } from "../components/animations";

// Menu images
import about from "../assets/menu-img-01.jpg";
import menu from "../assets/menu-img-02.jpg";
import menusmash from "../assets/menu-smash-img.jpg";
// import order from "../assets/menu-img-03.jpg";
// import reservations from "../assets/menu-img-04.jpg";
import contact from "../assets/menu-img-05.jpg";

const cities = [
  { name: "Smash Idzuo", path: "menusmash", image: menusmash },
  { name: "Јеловник/Поручи", path: "menu", image: menu },
  { name: "О нама", path: "about", image: about },
  // { name: "Достава", path: "order", image: order },
  // { name: "Резервације", path: "reservations", image: reservations },
  { name: "Контакт", path: "contact", image: contact },
];

//Transition duration
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const Home = () => {
  let imageBackground = useRef(null);

  const menuHolder = {
    initial: {
      y: 0,
    },
    animate: {
      y: 0,
      transition: {
        staggerChildren: 0.08,
        staggerDirection: 1,
      },
    },
  };

  const menuItems = {
    initial: {
      y: 400,
    },
    animate: {
      y: 0,
      transition: { duration: 0.6, ...transition },
    },
  };

  return (
    <>
      <Header />
      <div className="content row v-center h-center">
        <div className="navigation row v-center h-center">


          <motion.nav
            initial="initial"
            animate="animate"
            exit={{
              opacity: 0,
            }}
            variants={menuHolder}
          >
            <Link to="/menu" relative="path" className="offer-menu">Акција</Link>
            {cities.map((el) => (
              <span key={el.name}>
                <motion.span key={el.name} variants={menuItems}>
                  <Link
                    key={el.name}
                    to={el.path}
                    onMouseEnter={() => handleImage(el.image, imageBackground)}
                    onMouseOut={() => handleImageReturn(imageBackground)}
                  >
                    {el.name}
                  </Link>
                </motion.span>
              </span>
            ))}
          </motion.nav>
          <div className="menu-background">
            <div
              className="menu-image"
              ref={(el) => (imageBackground = el)}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
