import gsap from "gsap";
import { cubicBezier } from "framer-motion";


const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);

//DEFAULT TRANSITION
const transition = { duration: 0.8, ease: easing};

//MENU BACKGROUND ANIMATION
// adds image image once you hover on
export const handleImage = (image, target) => {
  gsap.to(target, {
    duration: 0,
    scale: 1.05,
    background: `url(${image}) center center no-repeat`,
  });
  gsap.to(target, {
    duration: 0.4,
    opacity: 0.4,
    transition: { transition },
  });
  gsap.from(target, {
    duration: 0.4,
    scale: 1,
  });
};

// Removes the image image once you hover off
export const handleImageReturn = (target) => {
  gsap.to(target, {
    duration: 0.4,
    opacity: 0,
  });
};

// notice
export const notice = (target) => {
  gsap.to(target, {
    duration: 0.4,
    opacity: 1,
  });
};
