import React, { useState } from "react";
import { motion, cubicBezier } from "framer-motion";
import { Link } from "react-router-dom";
import gsap from "gsap";
import emailjs from "emailjs-com";

//components
import Header from "../components/header";
import Footer from "../components/footer";
//Images
import heroImg from "../assets/menu-img-04.jpg";
import img1 from "../assets/menu-img-03.jpg";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";
//Default transition
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const Reservations = () => {
  const [status, setStatus] = useState("Проследите вашу резервацију");
  const [notice, setNotice] = useState(null);

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "idzuo_gmail",
        "idzuo_reservations",
        e.target,
        "user_lUaiZiRtuwbIPIhlGDaZk"
      )
      .then(
        (result) => {
          setNotice("Резервација послата");
          setStatus("Проследите вашу резервацију");
          gsap.to(".notice", {
            opacity: 1,
          });
          gsap.from(".notice", {
            opacity: 0,
            delay: 4,
          });
        },
        (error) => {
          setNotice("Грешка у слању");
          gsap.to(".notice", {
            opacity: 1,
            delay: 1,
          });
          gsap.from(".notice", {
            opacity: 0,
            delay: 4,
          });
        }
      );
    setStatus("Резервација се шаље");
    e.target.reset();
  }

  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="animate"
        exit={{
          opacity: 0,
          transition: {
            duration: 0.6,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        className="content has-bottom-border"
      >
        <div className="hero-section">
          <motion.span
            initial={{
              left: 0,
              opacity: 0,
            }}
            animate={{
              left: 60,
              opacity: 1,
              transition: { delay: 0.9, ...transition },
            }}
            className="hero-back"
          >
            <Link to="/">
              <ArrowLeft />
            </Link>
          </motion.span>
          <motion.h2
            initial={{
              y: 30,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.8, ...transition },
            }}
            className="hero-title"
          >
            Резервације
          </motion.h2>
          <motion.div
            initial={{
              width: "calc(100% - 120px)",
              height: "calc(100vh - 360px)",
            }}
            animate={{
              width: "100%",
              height: window.innerWidth > 1440 ? 500 : 350,
              transition: { delay: 0.2, ...transition },
            }}
            className="hero-img-holder"
          >
            <motion.img
              initial={{
                opacity: 0.4,
                scale: 1.05,
              }}
              animate={{
                transition: { delay: 0.2, ...transition },
              }}
              src={heroImg}
              alt="Идзуо - о нама"
            />
          </motion.div>
        </div>

        <div className="content-in">
          <div className="content-block">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
            >
              Резервишите сто за вас и вама драге особе и уживајте у нашем
              амбијенту уз специјалитете наше кухиње.
            </motion.p>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-img-inner"
          >
            <img
              className="content-img width-100"
              src={img1}
              alt="Ресторан идзуо"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-block"
          >
            <p>Резервишите сто позивом на број или путем мејла.</p>
            <a
              href="tel:065/404-80-48"
              rel="noreferr"
              className="p-3 white link-center fancy-link"
            >
              <span data-hover="065/404-80-48">065/404-80-48</span>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="tel:065/360-22-70"
              rel="noreferr"
              className="p-3 white link-center fancy-link"
            >
              <span data-hover="065/360-22-70">065/360-22-70</span>
            </a>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-block width-780"
          >
            <div className="notice">{notice}</div>
            <form onSubmit={sendEmail}>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Име и презиме"
                  required
                />
              </div>
              <div>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Телефон"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Имејл"
                  required
                />
              </div>
              <div>
                <input
                  type="date"
                  id="date"
                  name="date"
                  placeholder="Датум"
                  defaultValue="2021-01-01"
                  required
                />
              </div>
              <div>
                <input
                  type="time"
                  id="time"
                  name="time"
                  placeholder="Време"
                  defaultValue="08:00"
                  required
                />
              </div>
              <div>
                <input
                  type="number"
                  id="guests"
                  name="guests"
                  placeholder="Број особа"
                  required
                />
              </div>
              <div style={{ width: "100%" }}>
                <textarea
                  id="message"
                  name="message"
                  rows="10"
                  placeholder="Додатне напомене"
                />
              </div>
              <div>
                <button className="form-button" type="submit">
                  {status}
                </button>
              </div>
            </form>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            div
            className="content-block"
          >
            <h4>Резервације за недељу</h4>
            <p>
              Уколико желите да ручате у нашем ресторану и недељом, потребно је
              да резервишете сто позивом на број.
            </p>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default Reservations;
