export const smashOriginalData = [
    {
        id: 754,
        name: "Original Idzuo smash",
        subtitle: "",
        desc: "2x 70г 100% јунеће месо, 2x чедар сир, бургер сос, миx 9 салата, црвени лук, кисели краставац",
        grams: "",
        price: "510",
        image: "https://idzuo.com/menuImages/smash/smash-original.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 755,
        name: "Original Triple smash",
        subtitle: "",
        desc: "3x 70г 100% јунеће месо, 2x чедар сир, бургер сос, миx 9 салата, црвени лук, кисели краставац",
        grams: "",
        price: "580",
        image: "https://idzuo.com/menuImages/smash/smash-original.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 756,
        name: "Original Double - Double smash",
        subtitle: "",
        desc: "4x 70г 100% јунеће месо, 2x чедар сир, бургер сос, миx 9 салата, црвени лук, кисели краставац",
        grams: "",
        price: "650",
        image: "https://idzuo.com/menuImages/smash/smash-original.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 757,
        name: "Original Triple - Double smash",
        subtitle: "",
        desc: "6x 70г 100% јунеће месо, 2x чедар сир, бургер сос, миx 9 салата, црвени лук, кисели краставац",
        grams: "",
        price: "720",
        image: "https://idzuo.com/menuImages/smash/smash-original.webp",
        cart: "Додајте у корпу",
    },
];
  
  