export const sausageData = [
    {
        id: 301,
        name: "Кобасица са качкаваљем на тањиру",
        subtitle: "Тањир",
        desc: "Аустријска кобасица, помфрит, салата, ½ тортиље",
        grams: "",
        price: "490",
        image: "https://idzuo.com/menuImages/sausage/kobasica_sa_kackavaljem_na_tanjiru.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 302,
        name: "Кобасица са качкаваљом у тортиљи",
        subtitle: "Тортиља",
        desc: "Аустијска кобасица, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви у тортиљи",
        grams: "",
        price: "490",
        image: "https://idzuo.com/menuImages/sausage/kobasica_sa_kackavaljem_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 303,
        name: "Љута кобасица са качкаваљем на тањиру",
        subtitle: "Тањир",
        desc: "Аустријска кобасица, помфрит, салата, ½ тортиље",
        grams: "",
        price: "490",
        image: "https://idzuo.com/menuImages/sausage/ljuta_kobasica_sa_kackavaljem_na_tanjiru.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 304,
        name: "Љута кобасица са качкаваљем у тортиљи",
        subtitle: "Тортиља",
        desc: "Аустијска кобасица, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви у тортиљи",
        grams: "",
        price: "490",
        image: "https://idzuo.com/menuImages/sausage/ljuta_kobasica_sa_kackavaljem_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 305,
        name: "Пикантна кобасица на тањиру",
        subtitle: "Тањир",
        desc: "Аустријска кобасица, помфрит, салата, ½ тортиље црвени сос, бели сос",
        grams: "",
        price: "440",
        image: "https://idzuo.com/menuImages/sausage/pikantna_kobasica_na_tanjiru.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 306,
        name: "Пикантна кобасица у тортиљи",
        subtitle: "Тортиља",
        desc: "Аустријска кобасица, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви у тортиљи",
        grams: "",
        price: "440",
        image: "https://idzuo.com/menuImages/sausage/pikantna_kobasica_u_tortilji.webp",
        cart: "Додајте у корпу",
    },
  ];
  
  