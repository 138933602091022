export const saladData = [
    {
        id: 401,
        name: "Вегетаријанска салата (посно)",
        subtitle: "Тањир",
        desc: "Микс 9 салата, аћето балсамико, морска со, маринада, маслиново уље",
        grams: "",
        price: "390",
        image: "https://idzuo.com/menuImages/salads/vegeterijanska_salata.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 402,
        name: "Вегетаријанска салата са печуркама (посно)",
        subtitle: "Тањир",
        desc: "150г печурака, микс 9 салата, аћето балзамико, морска со, маринада, црвени сос",
        grams: "",
        price: "410",
        image: "https://idzuo.com/menuImages/salads/salata_sa_pecurkama.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 403,
        name: "Идзуо салата са пилетином",
        subtitle: "Тањир",
        desc: "150г пилећег филеа, микс 9 салата, аћето балзамико, морска со, маринада, бели сос",
        grams: "",
        price: "420",
        image: "https://idzuo.com/menuImages/salads/salata_sa_piletinom.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 404,
        name: "Идзуо салата са хрскавом пилетином",
        subtitle: "Тањир",
        desc: "Хрскава пилетина, микс 9 салата, аћето балзамико, морска со, маринада, бели сос",
        grams: "",
        price: "420",
        image: "https://idzuo.com/menuImages/salads/salata_hrskava_piletina.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 405,
        name: "Идзуо салата са туном (посно)",
        subtitle: "Тањир",
        desc: "Комади туне, микс 9 салата, аћето балзамико, морска со, маринада, црвени сос",
        grams: "",
        price: "440",
        image: "https://idzuo.com/menuImages/salads/tuna_salata.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 406,
        name: "Идзуо салата са рамстеком",
        subtitle: "Тањир",
        desc: "170г рамстека, микс 9 салата, аћето, балзамико, морска со, маринада, црвени сос",
        grams: "",
        price: "590",
        image: "https://idzuo.com/menuImages/salads/salata_rebrilja.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 407,
        name: "Идзуо салата са буткицом",
        subtitle: "Тањир",
        desc: "150г димљене буткице, микс 9 салата, аћето балзамико, морска со, маринада, црвени сос",
        grams: "",
        price: "430",
        image: "https://idzuo.com/menuImages/salads/salata-sa-butkicom.webp",
        cart: "Додајте у корпу",
    },
];