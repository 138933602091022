export const smashDrinksData = [
    {
        id: 551,
        name: "Кока кола 0,33",
        subtitle: "",
        desc: "",
        grams: "",
        price: "120",
        image: "https://idzuo.com/menuImages/drinks/coca_cola_0.33l.webp",
        cart: "Додајте у корпу",
      },
      {
        id: 553,
        name: "Кока кола зеро",
        subtitle: "",
        desc: "",
        grams: "",
        price: "120",
        image: "https://idzuo.com/menuImages/drinks/coca_cola_zero_limenka_0.33l.webp",
        cart: "Додајте у корпу",
      },

];
  
  