export const smashClassicData = [
    {
        id: 750,
        name: "Classic Idzuo smash",
        subtitle: "",
        desc: "2x 70г 100% јунеће месо, 2x чедар сир, црвени сос, сенф, црвени лук, кисели краставац",
        grams: "",
        price: "510",
        image: "https://idzuo.com/menuImages/smash/smash-classic.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 751,
        name: "Classic Triple smash",
        subtitle: "",
        desc: "3x 70г 100% јунеће месо, 2x чедар сир, црвени сос, сенф, црвени лук, кисели краставац",
        grams: "",
        price: "580",
        image: "https://idzuo.com/menuImages/smash/smash-classic.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 752,
        name: "Classic Double - Double smash",
        subtitle: "",
        desc: "4x 70г 100% јунеће месо, 2x чедар сир, црвени сос, сенф, црвени лук, кисели краставац",
        grams: "",
        price: "650",
        image: "https://idzuo.com/menuImages/smash/smash-classic.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 753,
        name: "Classic Triple - Double smash",
        subtitle: "",
        desc: "6x 70г 100% јунеће месо, 2x чедар сир, црвени сос, сенф, црвени лук, кисели краставац",
        grams: "",
        price: "720",
        image: "https://idzuo.com/menuImages/smash/smash-classic.webp",
        cart: "Додајте у корпу",
    },
];
  
  