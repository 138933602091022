export const smashSidesData = [
    // {
    //     id: 762,
    //     name: "Помфрит 250г",
    //     subtitle: "",
    //     desc: "",
    //     grams: "",
    //     price: "220",
    //     image: "https://idzuo.com/menuImages/smash/pomfrit-50g.webp",
    //     cart: "Додајте у корпу",
    // },
    {
        id: 763,
        name: "Помфрит 200г",
        subtitle: "",
        desc: "",
        grams: "",
        price: "170",
        image: "https://idzuo.com/menuImages/smash/pomfrit-50g.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 764,
        name: "Онион рингс 250г",
        subtitle: "",
        desc: "",
        grams: "",
        price: "240",
        image: "https://idzuo.com/menuImages/smash/onion-rings.webp",
        cart: "Додајте у корпу",
    },

];
  
  