export const galleryPhotos = [
  {
    id: "001",
    image: "http://idzuo.com/swiperImages/swiper-01.jpg",
  },
  {
    id: "002",
    image: "http://idzuo.com/swiperImages/swiper-02.jpg",
  },
  {
    id: "003",
    image: "http://idzuo.com/swiperImages/swiper-03.jpg",
  },
  {
    id: "004",
    image: "http://idzuo.com/swiperImages/swiper-04.jpg",
  },
  {
    id: "005",
    image: "http://idzuo.com/swiperImages/swiper-05.jpg",
  },
  {
    id: "006",
    image: "http://idzuo.com/swiperImages/swiper-06.jpg",
  },
  {
    id: "007",
    image: "http://idzuo.com/swiperImages/swiper-07.jpg",
  },
  {
    id: "008",
    image: "http://idzuo.com/swiperImages/swiper-08.jpg",
  },
  {
    id: "009",
    image: "http://idzuo.com/swiperImages/swiper-09.jpg",
  },
];
