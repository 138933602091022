import React from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { motion, cubicBezier } from "framer-motion";

//Transiton ease
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 1.2, ease: easing };

const footer = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 30,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: 30,
        opacity: 0,
      }}
      transition={transition}
      className="footer"
    >
      <div className="row v-center space-between">
        <div className="street white">
          <a
            className="fancy-link"
            href="https://www.google.com/maps/place/Vi%C5%A1nji%C4%87eva+3,+Kragujevac+34000/data=!4m2!3m1!1s0x475720df95c92b2f:0x267922456be3a8c3?sa=X&ved=2ahUKEwjj27Kl8KXuAhULLBoKHcH1CgQQ8gEwAHoECAQQAQ"
            target="_blank"
            rel="noreferrer"
          >
            <span data-hover="Вишњићева 3">Вишњићева 3</span>
          </a>
        </div>
        <div className="logo">
          <Logo />
        </div>
        <div className="socials">
          <a
            className="fancy-link"
            href="https://www.instagram.com/idzuo_sonasgarden/"
            target="_blank"
            rel="noreferrer"
          >
            <span data-hover="Инстаграм">Инстаграм</span>
          </a>
          <a
            className="fancy-link"
            href="https://sr-rs.facebook.com/idzuobrzahrana"
            target="_blank"
            rel="noreferrer"
          >
            <span data-hover="фејсбук">фејсбук</span>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default footer;
