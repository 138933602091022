import React, { useState } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import emailjs from "emailjs-com";

//SVG
import { ReactComponent as Plus } from "../assets/plus.svg";
import { ReactComponent as Minus } from "../assets/minus.svg";
import { ReactComponent as ShoppingCart } from "../assets/shoppingcart.svg";
import { ReactComponent as Close } from "../assets/close.svg";

export default function Cart(props) {

  const { cartItems, onAdd, onRemove, showNotice, useClickOutside } = props;

  const currentTime = new Date();
  const isOver22Clock = currentTime.getHours() >= 22;
  const calculateDeliveryPrice = (isOver22Clock) => {
    if (isOver22Clock) {
      return 150; // Delivery for orders above 400
    } else {
      return 100; // Delivery price is 150 if it's over 22:00
    }
  }; 
  const deliveryPrice = calculateDeliveryPrice(isOver22Clock);

  
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
  const totalPrice = itemsPrice + deliveryPrice;

  const [showCart, setShowCart] = useState(false);

  const handleToggleCart = () => {
    setShowCart(!showCart);
  };


  //Radio button
  const [formData, setFormData] = useState({
    isAgree: false,
    payment: "Кеш",
  });
  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //Outside Click
  const domNode = useClickOutside(() => {
    setShowCart(false);
  });

  const [status, setStatus] = useState("Поручи");

  const [notice, setNotice] = useState(null);

  const product = cartItems
    .map(
      (item) =>
        item.qty +
        " x " +
        item.name +
        " " +
        (!!item.subtitle ? item.subtitle : "")
    )
    .join("\r\n");

  const itemprice = cartItems.map((item) => item.price + " рсд").join("\r\n");


  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "idzuo_gmail",
        "idzuo_check",
        e.target,
        "user_lUaiZiRtuwbIPIhlGDaZk"
      )
      .then(
        (result) => {
          setNotice("Ваша поруџбина је прихваћена \r\n Време доставе 45-50мин");
          setStatus("Проследите поруџбину");
          gsap.to(".notice", {
            opacity: 1,
          });
          gsap.from(".notice", {
            opacity: 0,
            delay: 4,
          });
        },
        (error) => {
          setNotice("Грешка у слању");
          gsap.to(".notice", {
            opacity: 1,
            delay: 1,
          });
          gsap.from(".notice", {
            opacity: 0,
            delay: 4,
          });
        }
      );

    setStatus("Поруџбина се шаље");
    e.target.reset();
    setTimeout(() => {
      cartItems.length = 0;
    }, 300);
    gsap.to(".notice", {
      opacity:0,
    });
    localStorage.removeItem("cartItems");
  }

  return (
    <>
      <button
        className="shopping-cart-button"
        onClick={() => handleToggleCart()}
      >
        <ShoppingCart />
        {showNotice !== 0 ? (
          <motion.span
            initial={{ scale: 0, opacity: 0, top: "-20px" }}
            animate={{ scale: 1, opacity: 1, top: "-10px" }}
            className="cart-notice"
          >
            {cartItems.length}
          </motion.span>
        ) : null}
      </button>

      <div className={`cart${showCart ? " open" : ""}`} ref={domNode}>
        <span className="close" onClick={() => setShowCart(false)}>
          <Close />
        </span>
        {cartItems.length === 0 && (
          <div className="shpping-cart-title empty">Корпа је празна</div>
        )}
        {cartItems.length !== 0 && (
          <div className="shpping-cart-title">Ваша поруџбина</div>
        )}

        <div className="notice">{notice}</div>

        <form onSubmit={sendEmail}>
          {cartItems.map((item) => (
            <div key={item.id} className="cart-row">
              <span className="cart-title">{item.name}</span>

              <span className="cart-buttons">
                <span onClick={() => onRemove(item)} className="remove">
                  <Minus />
                </span>
                <span className="cart-buttons-qty">{item.qty}</span>
                <span onClick={() => onAdd(item)} className="add">
                  <Plus />
                </span>
              </span>

              <span className="cart-item-price">{item.price} рсд</span>
            </div>
          ))}
          {cartItems.length !== 0 && (
            <>
              <div className="cart-row">
                <span className="cart-title total">Достава</span>
                <span className="cart-price">{deliveryPrice} рсд</span>
              </div>
              <div className="cart-row">
                <span className="cart-title total">Укупно</span>
                <span className="cart-price">{totalPrice} рсд</span>
              </div>
              <div className="cart-inputs">
                <div className="input-invisible">
                  <textarea
                    type="text"
                    id="product"
                    name="product"
                    value={product}
                    readOnly
                    required
                  />
                  <textarea
                    type="text"
                    id="itemprice"
                    name="itemprice"
                    value={itemprice}
                    readOnly
                    required
                  />
                  <input
                    type="text"
                    id="totalprice"
                    name="totalprice"
                    value={totalPrice}
                    readOnly
                    required
                  />
                </div>

                <div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Ваше име"
                    required
                  />
                </div>
                <div>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    placeholder="Телефон"
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    id="street"
                    name="street"
                    placeholder="Адреса"
                    required
                  />
                </div>

                <div className="notice-msg">
                  <div className="shpping-cart-title">Напомена</div>
                  <textarea
                    type="text"
                    id="notice-msg"
                    name="notice-msg"
                    rows="4"
                    cols="50"
                  />
                </div>

                <div className="payment">
                  <div className="shpping-cart-title">Начин плаћања</div>
                  <div>
                    <input
                      type="radio"
                      id="payment-cash"
                      name="payment"
                      value="Кеш"
                      onChange={handleChange}
                      checked={formData.payment === "Кеш"}
                    />
                    <label htmlFor="payment-cash">Кеш</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="payment-card"
                      name="payment"
                      value="Картица"
                      onChange={handleChange}
                      checked={formData.payment === "Картица"}
                    />
                    <label htmlFor="payment-card">Картица</label>
                  </div>
                </div>
              </div>
              <div className="cart-row min-span">
                {totalPrice < 400 ? (
                  <span data-hover="Минимална цена је 400 дин">
                    Минимална цена је 400 дин
                  </span>
                ) : (
                  <button className="cart-checkout fancy-link" type="submit">
                    <span data-hover={status}>{status}</span>
                  </button>
                )}
              </div>
            </>
          )}
        </form>
      </div>
      {showCart ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className="productOverlay"
        ></motion.div>
      ) : null}
    </>
  );
}
