import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import gsap from "gsap";
import { AnimatePresence } from "framer-motion"
//style
import "./styles/App.scss";
//pages
import Home from "./pages/home";
import About from "./pages/about";
import Menu from "./pages/menu";
import MenuSmash from "./pages/menusmash";
import Order from "./pages/order";
import Reservations from "./pages/reservations";
import Contact from "./pages/contact";
import NoPageFound from "./pages/nopagefound";


//routes
const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/about", name: "About", Component: About },
  { path: "/menu", name: "Menu", Component: Menu },
  { path: "/menusmash", name: "MenuSmash", Component: MenuSmash },
  { path: "/order", name: "Order", Component: Order },
  { path: "/reservations", name: "Reservations", Component: Reservations },
  { path: "/contact", name: "Contact", Component: Contact },
  { path: "/nopagefound", name: "NoPageFound", Component: NoPageFound },
];

//Timer resizing
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

//APP START
const App = () => {
  //Windows resize
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  //Intro animation disappear
  //const [animationComplete, setAnimationComplete] = useState(false);
  //const completeAnimation = () => {
  //  setAnimationComplete(true);
  //};

  //USE EFFECTS
  useEffect(() => {
    //prevent flashing
    gsap.to("body", 0, { css: { visibility: "visible" } });

    //Animation disappear
    //homeAnimation(completeAnimation);

    //Trick height on mobiles
    let vh = dimensions.height * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    //Windows resize
    const debouncedHandleResize = debounce(function HandleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <>
      <div className="main">
        <AnimatePresence initial={true} mode='wait'>
          <Routes>
                {routes.map(({ path, Component }) => (
                  <Route
                    key={path}
                    path={path}
                    element={<Component />}
                  />
                ))}
          </Routes>
          </AnimatePresence>
      </div>
    </>
  );
};

export default App;
