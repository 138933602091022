export const barbecueData = [
    {
      id: 251,
      name: "Лесковачки уштипци у тортиљи",
      subtitle: "Тортиља",
      desc: "150г телећег меса, панчета, гауда, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "460",
      image: "https://idzuo.com/menuImages/barbeque/leskovacki_ustipak_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 252,
      name: "Лесковачки уштипци на тањиру",
      subtitle: "Тањир",
      desc: "300г телећег меса, панчета, гауда, микс салата, помфрит, ½ тортиље",
      grams: "",
      price: "520",
      image: "https://idzuo.com/menuImages/barbeque/leskovacki_ustipak.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 253,
      name: "Ћевапи у тортиљи",
      subtitle: "Тортиља",
      desc: "250г телећег меса, фета сир, црвени лук, љута туцана, сосеви",
      grams: "",
      price: "460",
      image: "https://idzuo.com/menuImages/barbeque/cevapi_u_tortiljji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 254,
      name: "Ћевапи на тањиру",
      subtitle: "Тањир",
      desc: "300г телећег меса, микс салата, помфрит, ½ тортиље",
      grams: "",
      price: "520",
      image: "https://idzuo.com/menuImages/barbeque/cevapi.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 255,
      name: "Пуњена пљескавица у тортиљи",
      subtitle: "Тортиља",
      desc: "150г телећег меса, панчета, трапист сир, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "410",
      image: "https://idzuo.com/menuImages/barbeque/punjena_pljeskavica_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 256,
      name: "Пуњена пљескавица на тањиру",
      subtitle: "Тањир",
      desc: "250г телећег меса, микс салата, помфрит, ½ тортиље",
      grams: "",
      price: "510",
      image: "https://idzuo.com/menuImages/barbeque/punjena_pljeskavica.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 257,
      name: "Пуњени ћевап у тортиљи ",
      subtitle: "Тортиља",
      desc: "150г телећег меса, quattri formaggi сос, печеница, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "480",
      image: "https://idzuo.com/menuImages/barbeque/punjena_pljeskavica_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
  ];
