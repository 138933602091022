import React from "react";
import { Link } from "react-router-dom";
import { motion, cubicBezier } from "framer-motion";
//components
import Header from "../components/header";
import Footer from "../components/footer";
//Images
import heroImg from "../assets/menu-img-03.jpg";
import img1 from "../assets/menu-img-02.jpg";
import img2 from "../assets/menu-img-04.jpg";
import img3 from "../assets/menu-img-01.jpg";
//SVG
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg";
import { ReactComponent as CreditCard01 } from "../assets/cart-01.svg";
import { ReactComponent as CreditCard02 } from "../assets/cart-02.svg";
import { ReactComponent as CreditCard03 } from "../assets/cart-03.svg";
import { ReactComponent as CreditCard04 } from "../assets/cart-04.svg";

//Default transition
const easing = cubicBezier(0.6, 0.01, -0.05, 0.9);
const transition = { duration: 0.8, ease: easing };

const Delivery = () => {
  return (
    <>
      <Header />
      <motion.div
        initial="initial"
        animate="animate"
        exit={{
          opacity: 0,
          transition: {
            duration: 0.6,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        className="content has-bottom-border"
      >
        <div className="hero-section">
          <motion.span
            initial={{
              left: 0,
              opacity: 0,
            }}
            animate={{
              left: 60,
              opacity: 1,
              transition: { delay: 0.9, ...transition },
            }}
            className="hero-back"
          >
            <Link to="/">
              <ArrowLeft />
            </Link>
          </motion.span>
          <motion.h2
            initial={{
              y: 30,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { delay: 0.8, ...transition },
            }}
            className="hero-title"
          >
            Достава
          </motion.h2>
          <motion.div
            initial={{
              width: "calc(100% - 120px)",
              height: "calc(100vh - 360px)",
            }}
            animate={{
              width: "100%",
              height: window.innerWidth > 1440 ? 500 : 350,
              transition: { delay: 0.2, ...transition },
            }}
            className="hero-img-holder"
          >
            <motion.img
              initial={{
                opacity: 0.4,
                scale: 1.05,
              }}
              animate={{
                transition: { delay: 0.2, ...transition },
              }}
              src={heroImg}
              alt="Идзуо - о нама"
            />
          </motion.div>
        </div>
        <div className="content-in">
          <div className="content-block">
            <motion.h4
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
            >
              Поручите телефоном
            </motion.h4>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.4 }}
              className="p-2"
            >
              Достава хране на вашу адресу позивом на број.
            </motion.p>
            <motion.a
              href="tel:065/360-22-70"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="p-3 white fancy-link link-center"
            >
              <span data-hover="065/360-22-70">065/360-22-70</span>
            </motion.a>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-img-inner"
            style={{ height: "350px", maxHeight: "350px" }}
          >
            <img
              className="content-img width-100"
              src={img1}
              alt="Ресторан идзуо"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-block"
          >
            <h4>Поручите онлајн</h4>
            <p className="p-2">
              Достава хране на вашу адресу онлајн поручивањем.
            </p>
            <Link
              className="fancy-link p-2 link-center"
              to="/menu"
              rel="noreferrer"
            >
              <span data-hover="Погледајте наш јеловник">
                Погледајте наш јеловник
              </span>
            </Link>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-img-50 flex"
          >
            <div
              className="content-img-inner"
              style={{ height: "350px", maxHeight: "350px" }}
            >
              <img className="img" src={img2} alt="Ресторан идзуо" />
            </div>
            <div
              className="content-img-inner"
              style={{ height: "350px", maxHeight: "350px" }}
            >
              <img className="img" src={img3} alt="Ресторан идзуо" />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-block"
          >
            <h4>Цена доставе</h4>
            <p className="p-2">
              <span className="white">Минимална цена</span> поруџбине за доставу
              је <span className="white">350 рсд.</span> Достава је гратис и
              важи за територију{" "}
              <span className="white">града крагујевца.</span>
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
            className="content-block cards"
          >
            <h4>Плаћање картицом</h4>
            <p className="p-2">
              Платите вашу поруџбину
              <span className="white"> кредитном картицом</span> при достави на
              лицу места.
            </p>
            <br />
            <br />
            <div className="flex space-between">
              <CreditCard01 />
              <CreditCard02 />
              <CreditCard03 />
              <CreditCard04 />
            </div>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default Delivery;
