export const drinksData = [
  {
    id: 551,
    name: "Кока кола 0,33",
    subtitle: "",
    desc: "",
    grams: "",
    price: "120",
    image: "https://idzuo.com/menuImages/drinks/coca_cola_0.33l.webp",
    cart: "Додајте у корпу",
  },
  // {
  //   id: 552,
  //   name: "Кока кола",
  //   subtitle: "",
  //   desc: "",
  //   grams: "",
  //   price: "140",
  //   image: "https://idzuo.com/menuImages/drinks/coca_cola_flasica_0.5.webp",
  //   cart: "Додајте у корпу",
  // },
  {
    id: 553,
    name: "Кока кола зеро",
    subtitle: "",
    desc: "",
    grams: "",
    price: "120",
    image: "https://idzuo.com/menuImages/drinks/coca_cola_zero_limenka_0.33l.webp",
    cart: "Додајте у корпу",
  },
  // {
  //   id: 554,
  //   name: "Фанта 0,33",
  //   subtitle: "",
  //   desc: "",
  //   grams: "",
  //   price: "120",
  //   image: "https://idzuo.com/menuImages/drinks/fanta_limenka_0.33.webp",
  //   cart: "Додајте у корпу",
  // },
  // {
  //   id: 555,
  //   name: "Некст јабука",
  //   subtitle: "",
  //   desc: "",
  //   grams: "",
  //   price: "140",
  //   image: "https://idzuo.com/menuImages/drinks/next_jabuka_0.2.webp",
  //   cart: "Додајте у корпу",
  // },
  // {
  //   id: 556,
  //   name: "Некст шумско воће",
  //   subtitle: "",
  //   desc: "",
  //   grams: "",
  //   price: "140",
  //   image: "https://idzuo.com/menuImages/drinks/next_sumsko_voce_0.2.webp",
  //   cart: "Додајте у корпу",
  // },
  // {
  //   id: 557,
  //   name: "Роса",
  //   subtitle: "",
  //   desc: "",
  //   grams: "",
  //   price: "100",
  //   image: "https://idzuo.com/menuImages/drinks/rosa_0.5.webp",
  //   cart: "Додајте у корпу",
  // },
  // {
  //   id: 558,
  //   name: "Ултра енерџи",
  //   subtitle: "",
  //   desc: "",
  //   grams: "",
  //   price: "120",
  //   image: "https://idzuo.com/menuImages/drinks/ultra_energy.webp",
  //   cart: "Додајте у корпу",
  // },
];
