import React from "react";

const About = () => {
    return (
        <>
         <h1>404</h1>
        </>
    )
};

export default About;
