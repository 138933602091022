import React from "react";
import { motion } from "framer-motion";
import Clicksound from "../assets/clicksound.mp3";
import { Howl, Howler } from "howler";

//SVG
import { ReactComponent as Close } from "../assets/close.svg";

export default function Modal(props) {
  const { activeObject, setShowNotice, setShowModal, onAdd, useClickOutside } =
    props;

  // Setup the new Howl.
  const sound = new Howl({
    src: Clicksound,
  });
  Howler.volume(0.2);

  const Product = ({
    activeObject: { name, subtitle, grams, image, price, desc },
  }) => (
    <motion.div
      initial={{ top: "49%" }}
      animate={{ top: "50%" }}
      transition={{ duration: 0.2 }}
      className="productModal"
      ref={domNode}
    >
      <span className="close" onClick={() => setShowModal(false)}>
        <Close />
      </span>
      <div className="product-img-holder">
        <img src={image} alt="img" />
      </div>
      <div className="product-details">
        <span className="list-title">
          {name}
        </span>
        <span className="list-grams">{grams}</span>
        <span className="list-price">{price} рсд</span>
        <span className="list-desc">{desc}</span>
        <span className="list-cart fancy-link">
          <button
            onClick={() => {
              onAdd(activeObject);
              setShowNotice(true);
              sound.play();
            }}
          >
            <span data-hover="Додајте у корпу">Додајте у корпу</span>
          </button>
        </span>
      </div>
    </motion.div>
  );

  //Outside Click
  const domNode = useClickOutside(() => {
    setShowModal(false);
  });

  return (
    <>
      <Product activeObject={activeObject} />
      {setShowModal ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className="productOverlay"
        ></motion.div>
      ) : null}
    </>
  );
}
