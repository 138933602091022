export const burgerData = [
    {
      id: 151,
      name: "Бургер у тортиљи",
      subtitle: "Тортиља",
      desc: "150г телећег меса, фета сир, црвени лук, кисели краставчићи, сосеви",
      grams: "",
      price: "330",
      image: "https://idzuo.com/menuImages/burgers/burger_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 152,
      name: "Бургер у лепињи",
      subtitle: "Лепиња",
      desc: "150г телећег меса, фета сир, црвени лук, кисели краставчићи, сосеви",
      grams: "",
      price: "330",
      image: "https://idzuo.com/menuImages/burgers/burger.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 153,
      name: "Чизбургер у тортиљи",
      subtitle: "Тортиља",
      desc: "150г телећег меса, трапист сир, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "410",
      image: "https://idzuo.com/menuImages/burgers/cheese_burger_u_tortilji.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 154,
      name: "Чизбургер у лепињи",
      subtitle: "Лепиња",
      desc: "150г телећег меса, трапист сир, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "410",
      image: "https://idzuo.com/menuImages/burgers/cheese_burger.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 155,
      name: "Чизбургер кватро формађи у тортиљи",
      subtitle: "Тортиља",
      desc: "150г телећег меса, quattro formaggi сос, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "440",
      image: "https://idzuo.com/menuImages/burgers/cheese_burger_quattro_formagio.webp",
      cart: "Додајте у корпу",
    },
    {
      id: 156,
      name: "Чизбургер кватро формађи у лепињи",
      subtitle: "Лепиња",
      desc: "150г телећег меса, quattro formaggi сос, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
      grams: "",
      price: "440",
      image: "https://idzuo.com/menuImages/burgers/burger_quattro_formagio.webp",
      cart: "Додајте у корпу",
    },
  ];
  