export const dessertData = [
    {
        id: 501,
        name: "Питица са нутелом",
        subtitle: "",
        desc: "150г нутела, шумско воће, плазма",
        grams: "",
        price: "240",
        image: "https://idzuo.com/menuImages/dessert/pitica-sa-nutelom.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 502,
        name: "Питица са кремом",
        subtitle: "",
        desc: "150г еурокрем, шумско воће, плазма",
        grams: "",
        price: "220",
        image: "https://idzuo.com/menuImages/dessert/pitica-sa-kremom.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 503,
        name: "Поховани сникерс",
        subtitle: "",
        desc: "120г поховани сникерс",
        grams: "",
        price: "240",
        image: "https://idzuo.com/menuImages/dessert/pohovani-snickers.webp",
        cart: "Додајте у корпу",
    },
];