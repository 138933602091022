export const offerData = [
    {
        id: 601,
        name: "Akcija 2 x Пилетина кватро формађи у тортиљи + гратис помфрит",
        subtitle: "",
        desc: "150г пилетине, панчета, сос четири врсте сира, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
        grams: "",
        price: "960",
        image: "https://idzuo.com/menuImages/chicken/piletina_quattro_formagio.webp",
        cart: "Додајте у корпу",
    },
];