export const smashJalapenoData = [
    {
        id: 758,
        name: "Bbq jalapeño",
        subtitle: "",
        desc: "2x 70г 100% јунеће месо, 2x чедар сир, bbq сос, онион рингс, јапалењо паприка",
        grams: "",
        price: "510",
        image: "https://idzuo.com/menuImages/smash/smash-jalapeno.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 759,
        name: "Bbq jalapeño - Triple smash",
        subtitle: "",
        desc: "3x 70г 100% јунеће месо, 2x чедар сир, bbq сос, онион рингс, јапалењо паприка",
        grams: "",
        price: "580",
        image: "https://idzuo.com/menuImages/smash/smash-jalapeno.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 760,
        name: "Bbq jalapeño - Double - Double smash",
        subtitle: "",
        desc: "4x 70г 100% јунеће месо, 2x чедар сир, bbq сос, онион рингс, јапалењо паприка",
        grams: "",
        price: "650",
        image: "https://idzuo.com/menuImages/smash/smash-jalapeno.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 761,
        name: "Bbq jalapeño - Triple - Double smash",
        subtitle: "",
        desc: "6x 70г 100% јунеће месо, 2x чедар сир, bbq сос, онион рингс, јапалењо паприка",
        grams: "",
        price: "720",
        image: "https://idzuo.com/menuImages/smash/smash-jalapeno.webp",
        cart: "Додајте у корпу",
    },
];
  
  