export const beefData = [
    {
        id: 201,
        name: "Рамстек у тортиљи",
        subtitle: "Тортиља",
        desc: "160г рамстека, фета сир, црвени лук, кисели краставчићи, микс салата, сосеви",
        grams: "",
        price: "730",
        image: "https://idzuo.com/menuImages/beef/rebrilja.webp",
        cart: "Додајте у корпу",
    },
    {
        id: 202,
        name: "Рамстек",
        subtitle: "Тањир",
        desc: "300г рамстека, микс салата, помфрит, ½ тортиље",
        grams: "",
        price: "990",
        image: "https://idzuo.com/menuImages/beef/piletina_na_tanjiru.webp",
        cart: "Додајте у корпу",
    },
  ];